import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './App.css';
import TopBar from './TopBar';
import UploadGames from './UploadGames';
import Table from './Table';
import History from './History';
import EloTimeline from './EloTimeline';
import Leagues from './Leagues';
import HeadToHead from './IndividualPlayer'

function App() {
  const [selectedButton, setSelectedButton] = useState('');

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  // Example placeholder for eloHistory to prevent error
  const eloHistory = [];
  const uniquePlayers = new Set(eloHistory.map(entry => entry.player_name)).size;

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <TopBar
            selectedButton={selectedButton}
            handleButtonClick={handleButtonClick}
          />
        </header>
        <div className="content-area">
          <Routes>
            <Route path="/upload-games" element={<UploadGames />} />
            <Route path="/table/*" element={<Table />} />
            <Route path="/elo-timeline" element={<EloTimeline />} />
            <Route path="/leagues" element={<Leagues />} />
            <Route path="/history" element={<History />} />
            <Route path="/headToHead" element={<HeadToHead />} />
            <Route path="/" element={<Home uniquePlayers={uniquePlayers} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

const Home = ({ uniquePlayers }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch('/homepage_summary/')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setData(data.players_data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div>
      <h1>Home</h1>
      <div className="analytics-box">
        {data && data.map((item, index) => (
          <div key={index} className="card">
            <div className="card-text">{item.name}</div>
            <div className="big-bold-number">{item.value}</div>
            <div className="sub-value-text">{item.sub_value}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
