import React, { useState, useEffect } from 'react';
import { TextField, Button, FormControlLabel, Checkbox, MenuItem, Select, InputLabel } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';
import './App.css'; // Import CSS file for styling

const UploadGames = () => {
  const [numPlayers, setNumPlayers] = useState(2);
  const [teamOnePlayers, setTeamOnePlayers] = useState([{ fullName: '' }]);
  const [teamTwoPlayers, setTeamTwoPlayers] = useState([{ fullName: '' }]);
  const [teamOneWinner, setTeamOneWinner] = useState(false);
  const [teamTwoWinner, setTeamTwoWinner] = useState(false);
  const [teamOneBallsRemaining, setTeamOneBallsRemaining] = useState(0);
  const [teamTwoBallsRemaining, setTeamTwoBallsRemaining] = useState(0);
  const [winningMethod, setWinningMethod] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [playerSuggestions] = useState([
    "Akarsh Sinha",
    "Dami Babalola",
    "Daryl Tan",
    "Davin Palakal",
    "Emily Woolf",
    "Gary Miller",
    "Harry Newman-Booth",
    "Henry Russel",
    "Jacob Wright",
    "James Fell",
    "Jamie Pullar",
    "John Lyon",
    "Keith Rodrigo",
    "Leticia Francis",
    "Lily Miler",
    "Maggie Xi",
    "Matt Bossley",
    "Mirelle Mhanna",
    "Quim Bolos",
    "Redan Yelden",
    "Rob Byron",
    "Ross Hill",
    "Ryan Cahill",
    "Sachin Turakhia",
    "Tim Wintle",
    "Tom Adams",
    "Tom Halson",
    "Truman Wright",
    "Vasilis Kafkas",
    "Will Newitt"

   
  ]);

  // Set default date to today's date
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero for months and days less than 10
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    setSelectedDate(`${year}-${month}-${day}`);
  }, []);

  const handleNumPlayersChange = (event) => {
    const numPlayers = parseInt(event.target.value);
    setNumPlayers(numPlayers);

    // Generate empty player arrays based on the selected number of players
    const teamOnePlayers = Array.from({ length: Math.ceil(numPlayers / 2) }, () => ({ fullName: '' }));
    const teamTwoPlayers = Array.from({ length: Math.floor(numPlayers / 2) }, () => ({ fullName: '' }));

    setTeamOnePlayers(teamOnePlayers);
    setTeamTwoPlayers(teamTwoPlayers);
  };

  const handlePlayerNameChange = (teamIndex, playerIndex, value) => {
    if (teamIndex === 1) {
      const updatedPlayers = [...teamOnePlayers];
      updatedPlayers[playerIndex].fullName = value;
      setTeamOnePlayers(updatedPlayers);
    } else {
      const updatedPlayers = [...teamTwoPlayers];
      updatedPlayers[playerIndex].fullName = value;
      setTeamTwoPlayers(updatedPlayers);
    }
  };

  const handleTeamOneWinnerChange = () => {
    if (!teamOneWinner) {
      setTeamOneWinner(true);
      setTeamTwoWinner(false);
      setWinningMethod('Team One');
    } else {
      setTeamOneWinner(false);
      setWinningMethod('');
    }
  };

  const handleTeamTwoWinnerChange = () => {
    if (!teamTwoWinner) {
      setTeamTwoWinner(true);
      setTeamOneWinner(false);
      setWinningMethod('Team Two');
    } else {
      setTeamTwoWinner(false);
      setWinningMethod('');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Prepare data to send to backend
    const formData = new FormData();
    formData.append('numPlayers', numPlayers);
    formData.append('teamOnePlayers', JSON.stringify(teamOnePlayers));
    formData.append('teamTwoPlayers', JSON.stringify(teamTwoPlayers));
    formData.append('teamOneWinner', teamOneWinner);
    formData.append('teamTwoWinner', teamTwoWinner);
    formData.append('teamOneBallsRemaining', teamOneBallsRemaining);
    formData.append('teamTwoBallsRemaining', teamTwoBallsRemaining);
    formData.append('winningMethod', winningMethod);
    formData.append('selectedDate', selectedDate);

    try {
      const response = await fetch('/submit_results/', {
        method: 'POST',
        body: formData
      });
      if (response.ok) {
        // Handle success response
        alert('Game result submitted successfully');
      } else {
        // Handle error response
        alert('Failed to submit game result');
      }
    } catch (error) {
      // Handle network error
      alert('Network error. Failed to submit game result');
    }
  };

  return (
    <div className="white-box">
      <div className="upload-games-container">
        <h1>Submit Pool Game Result</h1>
        <form className="upload-games-form" onSubmit={handleSubmit}>
          <div className="form-row">
            <label htmlFor="numPlayers">Select Number of Players:</label>
          </div>
          <div className="form-row">
            <select id="numPlayers" value={numPlayers} onChange={handleNumPlayersChange} className="player-dropdown">
              <option value="">--Select--</option>
              {[2, 3, 4, 5, 6, 7, 8].map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>
          </div>

          <div className="team-container">
            <div className="team-one">
              <h2>Team One</h2>
              {teamOnePlayers.map((player, index) => (
                <div key={index} className="player-names">
                  <Autocomplete
                    freeSolo
                    options={playerSuggestions}
                    getOptionLabel={(option) => option}
                    value={player.fullName}
                    onInputChange={(e, newValue) => handlePlayerNameChange(1, index, newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Player ${index + 1} Full Name`}
                        required
                      />
                    )}
                  />
                </div>
              ))}
            </div>

            <div className="team-two">
              <h2>Team Two</h2>
              {teamTwoPlayers.map((player, index) => (
                <div key={index} className="player-names">
                  <Autocomplete
                    freeSolo
                    options={playerSuggestions}
                    getOptionLabel={(option) => option}
                    value={player.fullName}
                    onInputChange={(e, newValue) => handlePlayerNameChange(2, index, newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Player ${index + 1} Full Name`}
                        required
                      />
                    )}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className='game-end'>
            <div className='team-one-game-end'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={teamOneWinner}
                    onChange={handleTeamOneWinnerChange}
                    disabled={!numPlayers || teamTwoWinner}
                  />
                }
                label="Is Team One Winner?"
              />
              <InputLabel id="teamOneBallsRemainingLabel">Balls Remaining</InputLabel>
              <Select
                labelId="teamOneBallsRemainingLabel"
                id="teamOneBallsRemaining"
                value={teamOneBallsRemaining}
                onChange={(e) => setTeamOneBallsRemaining(e.target.value)}
              >
                {[...Array(8).keys()].map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className='team-two-game-end'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={teamTwoWinner}
                    onChange={handleTeamTwoWinnerChange}
                    disabled={!numPlayers || teamOneWinner}
                  />
                }
                label="Is Team Two Winner?"
              />
              <InputLabel id="teamTwoBallsRemainingLabel">Balls Remaining</InputLabel>
              <Select
                labelId="teamTwoBallsRemainingLabel"
                id="teamTwoBallsRemaining"
                value={teamTwoBallsRemaining}
                onChange={(e) => setTeamTwoBallsRemaining(e.target.value)}
              >
                {[...Array(8).keys()].map((num) => (
                  <MenuItem key={num} value={num}>
                    {num}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="form-row">
            <InputLabel id="winningMethodLabel">Winning Method:</InputLabel>
            <select
              id="winningMethod"
              value={winningMethod}
              onChange={(e) => setWinningMethod(e.target.value)}
              className="player-dropdown"
            >
              <option value="">--Select--</option>
              <option value="Winner potted the black.">Winner potted the black.</option>
              <option value="Loser potted the black with the white.">Loser potted the black with the white.</option>
              <option value="Loser accidentally potted the black.">Loser accidentally potted the black.</option>
            </select>
          </div>

          <div className="form-row">
            <label htmlFor="selectedDate">Select Date:</label>
            <input
              type="date"
              id="selectedDate"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              required
            />
          </div>

          <div className="form-row">
            <Button variant="contained" color="primary" type="submit" disabled={!numPlayers}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadGames;
