import React, { useState, useEffect } from 'react';

const GameCard = ({ game, onDelete }) => {
    const handleDelete = () => {
      onDelete(game.id);
    };
  
    return (
    <div className="game-card-container">
        <div className="id-delete-container">
            <button className="delete-btn" onClick={handleDelete}>🗑️</button>
        </div>
        <div className="game-details">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Winning Method</th>
                <th>Number of Players</th>
                <th>Team One</th>
                <th>Remaining Balls (Team One)</th>
                <th>Potted Balls (Team One)</th>
                <th>Winner (Team One)</th>
                <th>Team Two</th>
                <th>Remaining Balls (Team Two)</th>
                <th>Potted Balls (Team Two)</th>
                <th>Winner (Team Two)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{game.date}</td>
                <td>{game.winning_method}</td>
                <td>{game.number_of_players}</td>
                <td className="player-names">
                  <ul>
                    {game.team_one.player_names.map((name, index) => (
                      <li key={index}>{name}</li>
                    ))}
                  </ul>
                </td>
                <td>{game.team_one.remaining_balls}</td>
                <td>{game.team_one.potted_balls}</td>
                <td>{game.team_one.winner}</td>
                <td className="player-names">
                  <ul>
                    {game.team_two.player_names.map((name, index) => (
                      <li key={index}>{name}</li>
                    ))}
                  </ul>
                </td>
                <td>{game.team_two.remaining_balls}</td>
                <td>{game.team_two.potted_balls}</td>
                <td>{game.team_two.winner}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  

const History = () => {
  const [games, setGames] = useState([]);

  useEffect(() => {
    fetch('/all_game_view/')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setGames(data.games);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleDelete = (id) => {
    const confirmation = prompt("To confirm deletion, type the passcode:");
    console.log(`User entered passcode: ${confirmation}`);
    console.log(`Expected passcode: "{{delete_key}}"`); // Ensure this is replaced correctly
  
    if (confirmation && confirmation.trim().toLowerCase() === "deletegameov") {
      console.log(`Attempting to delete game with id: ${id}`);
      fetch('/delete_game/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ id: id })
      })
        .then(response => {
          if (response.ok) {
            console.log('Game deleted successfully');
            // Remove the game item from the UI
            setGames(prevGames => prevGames.filter(game => game.id !== id));
          } else {
            console.error('Failed to delete game');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } else {
      alert("Deletion canceled.");
    }
  };
  
  return (
    <div className="game-container">
      <h1 style={{ textAlign: 'center' }}>All Games</h1>
      {games.map(game => (
        <GameCard key={game.id} game={game} onDelete={handleDelete} />
      ))}
    </div>
  );
}

export default History;
