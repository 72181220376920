import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const PlayerStatsCard = ({ playerName, gamesPlayed, wins, losses, pottedBalls, opponentPottedBalls }) => (
  <Card style={{ maxWidth: 345, margin: '10px auto', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
    <CardContent>
      <Typography variant="h5" component="div" gutterBottom>
        {playerName}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Games Played: {gamesPlayed}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Wins: {wins}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Losses: {losses}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Potted Balls: {pottedBalls}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Opponent Potted Balls: {opponentPottedBalls}
      </Typography>
    </CardContent>
  </Card>
);

export default PlayerStatsCard;
