import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import './App.css'; // Import CSS file for styling

function TableViewComponent() {
    const [playersData, setPlayersData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortBy, setSortBy] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // or 'desc'

    useEffect(() => {
        fetch('/table/') // Make sure this URL matches your Django endpoint
            .then(response => response.json()) // Parse the JSON response
            .then(data => {
                console.log('Response from server:', data.players_data); // Log the parsed JSON data
                // Update state with the received data
                setPlayersData(data.players_data);
                setLoading(false); // Update loading state
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false); // Update loading state in case of error
            });
    }, []);

    const handleSort = (column) => {
        if (sortBy === column) {
            // Reverse sort order if already sorting by the same column
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // Set new column to sort by
            setSortBy(column);
            setSortOrder('asc'); // Default to ascending order
        }
    };

    const sortedData = [...playersData].sort((a, b) => {
        if (sortBy) {
            const compareValue = a[sortBy] - b[sortBy];
            return sortOrder === 'asc' ? compareValue : -compareValue;
        }
        return 0;
    });

    return (
        <div className="upload-games-container">
            <h1>Table</h1>
            <div className="table-button-group">
                <Link to="/table">
                    <Button variant="contained" className="table-button selected">Table</Button>
                </Link>
                <Link to="/elo-timeline">
                    <Button variant="contained" className="table-button">Elo Timeline</Button>
                </Link>
                <Link to="/leagues">
                    <Button variant="contained" className="table-button">Leagues</Button>
                </Link>
            </div>

            <div className='table-box'>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" onClick={() => handleSort('name')}>Name</TableCell>
                                    <TableCell align="center" onClick={() => handleSort('games_played')}>Games Played</TableCell>
                                    <TableCell align="center" onClick={() => handleSort('elo')}>Elo</TableCell>
                                    <TableCell align="center" onClick={() => handleSort('wins')}>Wins</TableCell>
                                    <TableCell align="center" onClick={() => handleSort('losses')}>Losses</TableCell>
                                    <TableCell align="center" onClick={() => handleSort('balls_potted')}>Balls Potted</TableCell>
                                    <TableCell align="center" onClick={() => handleSort('average_balls_potted_per_game')}>Average Balls Potted per Game</TableCell>
                                    <TableCell align="center" onClick={() => handleSort('win_percentage')}>Win Percentage</TableCell>
                                    <TableCell align="center" onClick={() => handleSort('current_win_streak')}>Current Win Streak</TableCell>
                                    <TableCell align="center" onClick={() => handleSort('longest_win_streak')}>Longest Win Streak</TableCell>
                                    <TableCell align="center" onClick={() => handleSort('current_loss_streak')}>Current Loss Streak</TableCell>
                                    <TableCell align="center" onClick={() => handleSort('longest_loss_streak')}>Longest Loss Streak</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData.map(player => (
                                    <TableRow key={player.name}>
                                        <TableCell align="center">{player.name}</TableCell>
                                        <TableCell align="center">{player.games_played}</TableCell>
                                        <TableCell align="center">{player.elo}</TableCell>
                                        <TableCell align="center">{player.wins}</TableCell>
                                        <TableCell align="center">{player.losses}</TableCell>
                                        <TableCell align="center">{player.balls_potted}</TableCell>
                                        <TableCell align="center">{player.average_balls_potted_per_game}</TableCell>
                                        <TableCell align="center">{player.win_percentage}</TableCell>
                                        <TableCell align="center">{player.current_win_streak}</TableCell>
                                        <TableCell align="center">{player.longest_win_streak}</TableCell>
                                        <TableCell align="center">{player.current_loss_streak}</TableCell>
                                        <TableCell align="center">{player.longest_loss_streak}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </div>
    );
}

export default TableViewComponent;
