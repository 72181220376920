// TopBar.js
import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';

const TopBar = ({ selectedButton, handleButtonClick }) => (
  <div className="top-bar">
    <div className="logo">Pool-Vue</div>
    <Stack spacing={2} direction="row" className="menu">
      <Button
        variant={selectedButton === '' || selectedButton === '/' || selectedButton === 'Home' ? "contained" : "outlined"}
        component={NavLink}
        to="/"
        isActive={() => selectedButton === 'Home'}
        onClick={() => handleButtonClick('Home')}
      >
        Home
      </Button>
      <Button
        variant={selectedButton === 'Upload Games' ? "contained" : "outlined"}
        component={NavLink}
        to="/upload-games"
        onClick={() => handleButtonClick('Upload Games')}
      >
        Upload Games
      </Button>
      <Button
        variant={selectedButton === 'Table' ? "contained" : "outlined"}
        component={NavLink}
        to="/table"
        onClick={() => handleButtonClick('Table')}
      >
        Stats
      </Button>
      <Button
        variant={selectedButton === 'headToHead' ? "contained" : "outlined"}
        component={NavLink}
        to="/headToHead"
        onClick={() => handleButtonClick('headToHead')}
      >
        Head to Head
      </Button>
      <Button
        variant={selectedButton === 'History' ? "contained" : "outlined"}
        component={NavLink}
        to="/history"
        onClick={() => handleButtonClick('History')}
      >
        History
      </Button>
      
    </Stack>
  </div>
);



export default TopBar;
