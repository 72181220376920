import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import './App.css'; // Import CSS file for styling

function TableViewComponent() {
    const [playersData, setPlayersData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch('/table/') // Make sure this URL matches your Django endpoint
            .then(response => response.json()) // Parse the JSON response
            .then(data => {
                console.log('Response from server:', data.players_data); // Log the parsed JSON data
                // Update state with the received data
                setPlayersData(data.players_data);
                setLoading(false); // Update loading state
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false); // Update loading state in case of error
            });
    }, []);

    // Function to split array into chunks
    const chunkArray = (array, size) => {
        const chunkedArr = [];
        let index = 0;
        while (index < array.length) {
            chunkedArr.push(array.slice(index, size + index));
            index += size;
        }
        return chunkedArr;
    };

    // Split playersData into chunks and sort each chunk by elo
    const sortedChunks = chunkArray([...playersData].sort((a, b) => b.elo - a.elo), Math.ceil(playersData.length / 4));

    return (
        <div className="upload-games-container">
            <h1>Leagues</h1>
            <div className="table-button-group">
                <Link to="/table">
                    <Button variant="contained" className="table-button selected">Table</Button>
                </Link>
                <Link to="/elo-timeline">
                    <Button variant="contained" className="table-button">Elo Timeline</Button>
                </Link>
                <Link to="/leagues">
                    <Button variant="contained" className="table-button">Leagues</Button>
                </Link>
            </div>

            <div className='table-box'>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                        sortedChunks.map((chunk, index) => (
                            <div key={index}>
                                <h2>
                                    {index === 0 ? 'Premier League' :
                                        index === 1 ? 'Championship' :
                                            index === 2 ? 'League One' :
                                                'League Two'}
                                </h2>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Name</TableCell>
                                                <TableCell align="center">Games Played</TableCell>
                                                <TableCell align="center">Elo</TableCell>
                                                <TableCell align="center">Wins</TableCell>
                                                <TableCell align="center">Losses</TableCell>
                                                <TableCell align="center">Balls Potted</TableCell>
                                                <TableCell align="center">Average Balls Potted per Game</TableCell>
                                                <TableCell align="center">Win Percentage</TableCell>
                                                <TableCell align="center">Current Win Streak</TableCell>
                                                <TableCell align="center">Longest Win Streak</TableCell>
                                                <TableCell align="center">Current Loss Streak</TableCell>
                                                <TableCell align="center">Longest Loss Streak</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {chunk.map(player => (
                                                <TableRow key={player.name}>
                                                    <TableCell align="center">{player.name}</TableCell>
                                                    <TableCell align="center">{player.games_played}</TableCell>
                                                    <TableCell align="center">{player.elo}</TableCell>
                                                    <TableCell align="center">{player.wins}</TableCell>
                                                    <TableCell align="center">{player.losses}</TableCell>
                                                    <TableCell align="center">{player.balls_potted}</TableCell>
                                                    <TableCell align="center">{player.average_balls_potted_per_game}</TableCell>
                                                    <TableCell align="center">{player.win_percentage}</TableCell>
                                                    <TableCell align="center">{player.current_win_streak}</TableCell>
                                                    <TableCell align="center">{player.longest_win_streak}</TableCell>
                                                    <TableCell align="center">{player.current_loss_streak}</TableCell>
                                                    <TableCell align="center">{player.longest_loss_streak}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        ))
                    )}
            </div>
        </div>

    );
}

export default TableViewComponent;
