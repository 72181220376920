import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'; // Import chart components

class EloHistoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eloHistory: [],
            transformedData: [],
            loading: true,
            minScore: 1400,
            selectedPlayers: [] // Store selected player names
        };
    }

    componentDidMount() {
        fetch('/table/') // Make sure this URL matches your Django endpoint
            .then(response => response.json()) // Parse the JSON response
            .then(data => {
 
                const eloHistory = data.players_data_elo_history;

                // Transform the data
                const transformedData = this.transformData(eloHistory);

                const min = Math.min(...eloHistory.flatMap(player => player.scores.map(score => score.score)));
                this.setState({
                    eloHistory: eloHistory,
                    transformedData: transformedData,
                    minScore: min * 0.95, // Set minimum score to 95% of the calculated minimum value
                    loading: false // Update loading state
                });
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.setState({ loading: false }); // Update loading state in case of error
            });
    }

    transformData(eloHistory) {
        const dates = [...new Set(eloHistory.flatMap(player => player.scores.map(score => score.date)))];
        return dates.map(date => {
            const dataPoint = { date };
            eloHistory.forEach(player => {
                const scoreEntry = player.scores.find(score => score.date === date);
                dataPoint[player.player_name] = scoreEntry ? scoreEntry.score : null;
            });
            return dataPoint;
        });
    }

    generateRandomColor() {
        return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }

    handlePlayerToggle = (playerName) => {
        const { selectedPlayers } = this.state;
        if (selectedPlayers.includes(playerName)) {
            // If player is already selected, remove it
            this.setState({
                selectedPlayers: selectedPlayers.filter(player => player !== playerName)
            });
        } else {
            // If player is not selected, add it
            this.setState({
                selectedPlayers: [...selectedPlayers, playerName]
            });
        }
    }

    render() {
        const { eloHistory, transformedData, loading, selectedPlayers } = this.state;

        return (
            <div className="upload-games-container">
                <h1>Elo History</h1>
                <div className="table-button-group">
                    <Link to="/table">
                        <Button variant="contained" className="table-button selected">Table</Button>
                    </Link>
                    <Link to="/elo-timeline">
                        <Button variant="contained" className="table-button">Elo Timeline</Button>
                    </Link>
                    <Link to="/leagues">
                        <Button variant="contained" className="table-button">Leagues</Button>
                    </Link>
                </div>
                <div className='table-box'>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div className="chart-container" >
                            <div>
                                {eloHistory.map((player, index) => (
                                    <label key={index}>
                                        <input
                                            type="checkbox"
                                            checked={selectedPlayers.includes(player.player_name)}
                                            onChange={() => this.handlePlayerToggle(player.player_name)}
                                        />
                                        {player.player_name}
                                    </label>
                                ))}
                            </div>
                            <ResponsiveContainer width="100%" height={400}>
                            <LineChart
                                data={transformedData}
                                margin={{ top: 5, right: 30, left: 20, bottom: 50 }} // Increase bottom margin to 50
                            >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" label={{ value: 'Date', position: 'insideBottom' }} angle={-45} textAnchor="end" />

                                  
                                    <YAxis domain={[1400, 'auto']} />
                                    <Tooltip />
                             
                                    {selectedPlayers.map((player, index) => (
                                        <Line
                                            key={index}
                                            type="monotone"
                                            dataKey={player}
                                            name={player}
                                            stroke={this.generateRandomColor()}
                                            strokeWidth={2}
                                        />
                                    ))}
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default EloHistoryComponent;
